import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Card, Dropdown, Form, Col, InputGroup, Alert, Row, Button, Badge} from 'react-bootstrap';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import DatePicker from  "react-datepicker";
import useShipmentCompany from 'hooks/useShipmentCompany';
import Accordion from 'react-bootstrap/Accordion';
import useVendorStore from 'hooks/useVendorStore';
import gifOrder from 'assets/img/gif_order.gif'
import useInventoryListFilter from 'hooks/useInventoryListFilter';
import {  PermsContext } from 'context/PermsContext';
import useVendorList from 'hooks/useVendorList';
import useOrderWork from 'hooks/useOrderWork';
import useOrderWorkListPost from 'hooks/useOrderWorkListPost';
import AdvanceTableWrapperOrderWork from 'components/common/advance-table/AdvanceTableWrapperOrderWork';
import { CiCalendarDate, CiShop, CiAlignBottom, CiBarcode, CiBoxes, CiReceipt, CiBank, CiMap, CiViewList, CiTimer, CiSearch, CiCreditCard2 } from "react-icons/ci";
// import { PiPrinterThin } from "react-icons/pi";
import { SlPrinter } from "react-icons/sl";
import { toast } from 'react-toastify';
import { DateContext } from 'context/DateContext';
import { useSelectedOrders } from 'context/selectedOrdersReducer';
import useOrderWaybill from 'hooks/useOrderWaybillList';
import OrdersTableHeader from './OrdersTableHeader';
import useProductCatalog from 'hooks/useProductCatalog';
import PaginationOrder from './PaginationOrder';
import useOrderWaybillList from 'hooks/useOrderWaybillList';
import useOrderWaybill_1 from 'hooks/useOrderWaybill_1';
import AdvanceTableWrapperOrderWaybill from 'components/common/advance-table/AdvanceTableWrapperOrderWaybill';
import useOrderWaybill_2 from 'hooks/useOrderWaybill_2';
import useOrderWaybill_3 from 'hooks/useOrderWaybill_3';
import useBarcodeSku from 'hooks/useBarcodeSku';
import AppContext from 'context/Context';
import { MdClear } from "react-icons/md";
import { MdCheck } from "react-icons/md";



const OrdersPrint = () => {

  const columns = [
    {
      accessor: 'name',
      Header: 'Sipariş',
      disableSortBy: true,
      headerProps: { className: 'text-left'},
      cellProps: {
        className: 'text-left'
      },
      Cell: rowData => {
        const { id, name, email } = rowData.row.original;
        return (
          <>
              <strong style={{color:'#3A81E7'}}>{id}</strong>
           {' '}
            by <strong style={{color:'#3A81E7'}}>{name}</strong> <br />
           
            <p style={{color:'#3A81E7', marginBottom:'0px'}}>{email}</p>
           
          </>
        );
      }
    },
    {
      accessor: 'date',
      // Header: 'Tutar',
      Header: () => (
        <div 
          style={{display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}} 
          className='text-center' 
          onClick={onHeaderDateClick}
        >
         Tarih &nbsp; 
         {
              ascDate === 1 && <span style={{display: 'block', fontSize:"9px", color:"#B5C2D1"}}>&#9650;</span> // Artan sıralama
            }
            {
              ascDate === 2 && <span style={{display: 'block', fontSize:"9px", color:"#B5C2D1"}}>&#9660;</span> // Azalan sıralama
            }
            {
              ascDate === 3 && (
                <div>
                  <span style={{display: 'block', marginBottom:"-5px", fontSize:"8px", color:"#B5C2D1"}}>&#9650;</span> 
                  <span style={{display: 'block', fontSize:"8px", color:"#B5C2D1"}}>&#9660;</span> 
                </div>
              ) // Sıralama yok
            }
        </div>
      ),
      disableSortBy: true,
      headerProps: {
        className: 'text-left'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => `${rowData.row.original.date}`
    },
    {
      accessor: 'deadlinedate',
      // Header: 'Tutar',
      Header: () => (
        <div 
          style={{display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}} 
          className='text-center' 
          onClick={onHeaderDeadLineDateClick}
        >
         Termin Tarihi &nbsp; 
            {
              ascDeadlineDate === 1 && <span style={{display: 'block', fontSize:"9px", color:"#B5C2D1"}}>&#9660;</span> 
            }
            {
              ascDeadlineDate === 2 && <span style={{display: 'block', fontSize:"9px", color:"#B5C2D1"}}>&#9650;</span> 
            }
            {
              ascDeadlineDate === 3 && (
                <div>
                  <span style={{display: 'block', marginBottom:"-5px", fontSize:"8px", color:"#B5C2D1"}}>&#9650;</span> 
                  <span style={{display: 'block', fontSize:"8px", color:"#B5C2D1"}}>&#9660;</span> 
                </div>
              )
    }
        </div>
      ),
      disableSortBy: true,
      headerProps: {
        className: 'text-left'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => `${rowData.row.original.deadlinedate}`
    },
    {
      accessor: 'waybilldate',
      // Header: 'Tutar',
      Header: () => (
        <div 
          style={{display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}} 
          className='text-center' 
          onClick={onHeaderWaybillDateClick}
        >
         Barkod Yazdırılma Tarihi &nbsp; 
            {
              ascWaybillDate === 1 && <span style={{display: 'block', fontSize:"9px", color:"#B5C2D1"}}>&#9660;</span> 
            }
            {
              ascWaybillDate === 2 && <span style={{display: 'block', fontSize:"9px", color:"#B5C2D1"}}>&#9650;</span> 
            }
            {
              ascWaybillDate === 3 && (
                <div>
                  <span style={{display: 'block', marginBottom:"-5px", fontSize:"8px", color:"#B5C2D1"}}>&#9650;</span> 
                  <span style={{display: 'block', fontSize:"8px", color:"#B5C2D1"}}>&#9660;</span> 
                </div>
              )
    }
        </div>
      ),
      disableSortBy: true,
      headerProps: {
        className: 'text-left'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => `${rowData.row.original.waybillDate}`
    },
    {
      accessor: 'address',
      Header: 'Şehir ve Kargo Bilgileri',
      disableSortBy: true,
      headerProps: {
        className: 'text-left'
      },
      cellProps: {
        className: 'text-left'
      },
      Cell: rowData => {
        const { address, shippingType, trackingLink } = rowData.row.original;
        return (
          <>
            {address}
            {trackingLink ? (
              <p>
             <a href={trackingLink} target="_blank" rel="noopener noreferrer" className="mb-0 text-500" >
             <strong style={{color:"#2C7AE5"}} >{shippingType}</strong>
                </a></p>
            ) : (
              <p className="mb-0 text-500" onClick={() => alert('Şu an takip linki oluşmamışdır.')}>
                {shippingType}
              </p>
            )}
          </>
        );
      }
      
    },
    {
      accessor: 'status',
      Header: 'Durumu',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-left'
      },
      Cell: rowData => {
        const { status } = rowData.row.original;
        return (
          <SoftBadge
            pill
            bg={classNames({
              secondary : status === '8', // delivered
              success: status === '7', // delivered
              danger: status === '6', // delivered
              light: status === '5',   // returned
              primary: status === '4',   // completed
              info: status === '3',  // processing
              warning: status === '2',   // pending
              dark : status === '1'   // onhold
            })}
            className="d-block"
          >
            {status === '8' && 'Teslim Edilmedi'} 
            {status === '7' && 'Teslim Edildi'} 
            {status === '6' && 'İptal Edildi'} 
            {status === '5' && 'İade Edildi'} 
            {status === '4' && 'Kargoya Verildi'}  
            {status === '3' && 'Paketlendi'} 
            {status === '2' && 'Hazırlanıyor'} 
            {status === '1' && 'Beklemede'}  
            <FontAwesomeIcon
              icon={classNames({
                check: status === '7',
                check: status === '4',
                redo: status === '3',
                stream: status === '2',
                ban: status === '1'
              })}
              transform="shrink-2"
              className="ms-1"
            />
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'amount',
      // Header: 'Tutar',
      Header: () => (
        <div 
          style={{display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}} 
          className='text-center' 
          onClick={onHeaderClick}
        >
         SKU ve Adet &nbsp; 
          
        </div>
      ),
      disableSortBy: true,
      headerProps: {
        className: 'text-left'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => `${rowData.row.original.products}`
    },
    {
      accessor: 'none',
      Header: 'Mağaza',
      disableSortBy: true,
      cellProps: {
        className: 'text-left'
      },
      Cell: rowData => {
        const { store} = rowData.row.original;
        return (
          <p>{store}</p>
        );
      }
    },
    
  ];

  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);



const {perms} = useContext(PermsContext)


const {myData,setMyData,setMyDataS3, selectStatusModal, setSelectStatusModal,formattedStartDate, startDateModal, setStartDateModal, endDateModal, setEndDateModal,  cargoModal, setCargoModal, getDataExport, storeModal,setStoreModal, fulfilmentModal, setFulfilmentModal, showGif, setShowGif, setInvoicesExport, invoicesExport,regionExport, setRegionExport, myDataResult, isPreparing, setIsPreparing, setMyDataResult, setVendorExportId, showOrderGif, currentPage,
  setCurrentPage, followersPerPage, setFollowersPerPage, totalPages, sortAmount, setSortAmount, setPage,vendorIdList, setVendorIdList, setCitySelect, citySelect, selectWaybill,setSelectWaybill, deadlinedateSelect, setDeadlinedateSelect, selectZpl,setSelectZpl, paid, setPaid, search, setSearch, currentTerm, setCurrentTerm, searchTerms, setSearchTerms, setAscDeadlineDate, setAscDate, ascDate, ascDeadlineDate, setTriggerExport, setSku1, setSku2, sku1, sku2, productCount, setProductCount,  setTotalIdStatus,totalIdStatus, setAscWaybillDate, ascWaybillDate, setInvoiceCountry,invoiceCountry, setVendorIds, setTranckingNumber, setLoading, loading}  = useOrderWaybillList()

  
  
 

const {myDataVendor} = useVendorList()

const {vendorStore, setVendorIdVendorStore} = useVendorStore()
const {myDataRegion, setVendorIdRegion} = useInventoryListFilter()
const {shipmentCompany, setVendorIdShipment} = useShipmentCompany()

const {catalogData, setVendorIdCatalog} = useProductCatalog()

const {getData, setVendorIdBarcode} = useBarcodeSku()


const {postDataWaybill_1, setLoading_1, loading_1} = useOrderWaybill_1()
const {postDataWaybill_2, loading_2, setLoading_2} = useOrderWaybill_2()
const {postDataWaybill_3, loading_3, setLoading_3} = useOrderWaybill_3()






const handleChangeSearch = (e) => {
  setCurrentTerm(e.target.value);
};

const handleAddTerm = () => {
  if (currentTerm && !searchTerms.includes(currentTerm)) {
      setSearchTerms(prevTerms => [...prevTerms, currentTerm]);
      setCurrentTerm('');
  }
};

const handleRemoveTerm = (term) => {
  setSearchTerms(prevTerms => prevTerms.filter(t => t !== term));
};




const onHeaderClick = () => {
  setSortAmount((sortAmount % 3) + 1);
  setTriggerExport(prev => !prev);
};

const onHeaderDateClick = () => {
  setAscDate((ascDate % 3) + 1);
  setTriggerExport(prev => !prev);
};


const onHeaderDeadLineDateClick = () => {
  setAscDeadlineDate((ascDeadlineDate % 3) + 1);
};

const onHeaderWaybillDateClick = () => {
  setAscWaybillDate((ascWaybillDate % 3) + 1);
};


  const [activePage, setActivePage] = useState(1)


  function hasPermission(permission) {
    return perms?.includes(permission);
  }
 
  const onChangeModal = datesExport => {
    const [startExport, endExport] = datesExport;
    setStartDateModal(startExport);
    setEndDateModal(endExport);
  };


 

  useEffect(() => {
    if (myDataVendor && myDataVendor.length == 1) {
      const firstVendorId = myDataVendor[0].Id;
      setVendorIdVendorStore(firstVendorId);
      setVendorIdRegion(firstVendorId);
      setVendorIdShipment(firstVendorId);
      // setVendorIdList(firstVendorId)
      setVendorIds(firstVendorId)
      setVendorExportId(firstVendorId)
      setVendorIdCatalog(firstVendorId)
      setVendorIdBarcode(firstVendorId)
    }
  }, [myDataVendor]);



  // const handleChangeVendor = (e)=> {
  //   setVendorIdVendorStore(e.target.value); 
  //   setVendorIdRegion(e.target.value)
  //   setVendorIdShipment(e.target.value)
  //   setVendorIdList(e.target.value)
  //   setVendorIdCatalog(e.target.value)
  //   setVendorIdBarcode(e.target.value)
    
  // }

  const handleChangeModal = (e)=> {
    setSelectStatusModal(+e.target.value); 
    console.log("type", typeof e.target.value)
  }

  const handleChangeCargoModal = (e)=> {
    setCargoModal(e.target.value); 
  }

  const handleChangeStoreModal = (e)=> {
    setStoreModal(e.target.value); 
  }


  const handleChangeRegionModal = (e)=> {
    setRegionExport(e.target.value); 
  }

  



  useEffect(() => {
    setPage(activePage)
  }, [activePage])
  
  const orderStatus = [
    {value:0, label:"Hepsi"},
    {value:1, label:"Beklemede"},
    {value:2, label:"Hazırlanıyor"},
    {value:3, label:"Paketlendi"},
    {value:4, label:"Kargoya verildi"},
    {value:5, label:"İade"},
    {value:6, label:"İptal"},
    {value:7, label:"Teslim Edildi"},
    {value:8, label:"Teslim Edilmedi"},

]

const orderPaid = [
  {value : "True", label : "Ödendi"},
  {value : "False", label : "Ödenmedi"}
]

const fulfilmentStatus = [
  {value:0, label:"Hepsi"},
  {value:1, label:"Gönderilmedi"},
  {value:4, label:"Gönderildi"},

]

const invoicesStatus = [
  {value:"true", label:"Faturalı"},
  {value:"false", label:"Faturasız"},
]

const city = [
  {value:"istanbul", label:"İstanbul"},
  {value:"istanbul_disi", label:"İstanbul Dışı"},
  {value:"ankara", label:"Ankara"},
  {value:"izmir", label:"İzmir"},
  {value:"diger_sehirler", label:"Diğer Şehirler"},
]

const waybill = [
  {value:"true", label:"Çıktı"},
  {value:"false", label:"Çıkmadı"},
]

const deadlinedate = [
  {value:"bugun", label:"Bugün"},
  {value:"yarin", label:"Yarın"},
  {value:"ertesi_gün", label:"Ertesi Gün"},
]

const zpl = [
  {value:"true", label:"Var"},
  {value:"false", label:"Yok"},
]



const ActionTypes = [
  { value: "waybill1", label: "İrsaliye Yazdır" },
  { value: "waybill2", label: "Seri İrsaliye Yazdır" },
  { value: "waybill3", label: "Yüklenen Dokümanları Yazdır" },
];

const [selectedAction, setSelectedAction] = useState("");

const handleChangeWaybillAction = (event) => {
  setSelectedAction(event.target.value);
};


const ProductCount = [
  {value:'tek_adet', label:"Tek Adet"},
  {value:'tek_adet_karisik', label:"Tek Adet Karışık"},
  {value:'iki_adet', label:"İki Adet"},
  {value:'diger', label:"Diğer"}

]

const InvoiceCountry = [
  {value:'Türkiye', label:'Türkiye'},
  {value:'Azerbeycan', label:'Azerbeycan'},
  {value:'Rossija', label:'Rossija'},
  
]


const handleChangeCountry = (e)=> {
  setInvoiceCountry(e.target.value); 
}



const handleChangePaid = (e)=> {
  setPaid(e.target.value); 
}


const handleChangeExportinvoices = (e)=> {
  setInvoicesExport(e.target.value); 
}

const handleChangeCity = (e)=> {
  setCitySelect(e.target.value); 
}

const handleChangeWaybill = (e)=> {
  setSelectWaybill(e.target.value); 
}

const handleChangeDeadlinedate = (e)=> {
  setDeadlinedateSelect(e.target.value); 
}

const handleChangeZpl = (e)=> {
  setSelectZpl(e.target.value); 
}

const handleChangeProductCount = (e) =>{
  setProductCount(e.target.value)
}

const handleChangeSku1 = (e) =>{
  // setSku1(e.target.value)
}

const handleChangeSku2 = (e) =>{
  setSku2(e.target.value)
}



  const newList = myData?.results ? myData?.results.map((item)=>{

 
      function formatDate(dateString) {

        if (!dateString || isNaN(new Date(dateString).getTime())) {
          return "tarihi yok";
        }
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        
      
        return `${day}-${month}-${year}`;
      }

      function formatDateTime(dateString) {

        if (!dateString || isNaN(new Date(dateString).getTime())) {
          return "tarihi yok";
        }
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
      
        return `${day}-${month}-${year} ${hours}:${minutes}`;
      }
      

      const productsString = item.OrderProducts.map(product => 
        `${product.SKU}x${product.Count}`
      ).join(', ');
    

    return {
      id : item.Id,
      name: item.Customer.FirstName+ " "+item.Customer.LastName, 
      email : item.OrderNumber === null ? "Sipariş No: -" : "Sipariş No: " + item.OrderNumber,
      date: formatDateTime(item.OrderDate),
      deadlinedate: formatDateTime(item.DeadlineDate),
      waybillDate : formatDateTime(item.WaybillPrintDate),
      address : item.ShippingAddress.City,
      shippingType: ((item.ShipmentCompanyText && item.ShipmentCompanyText.includes("Marketplace")) ? item.ShipmentCompanyText.replace('Marketplace', '').trim() : item.ShipmentCompanyText ? item.ShipmentCompanyText : "Kargolamayı bekliyor") + " " + (item.ShippingTrackingNumber ? item.ShippingTrackingNumber : "Henüz Kargolanmadı"),
      status: item.Status,
      amount : item.OrderAmount,
      store : item.VendorStore.Store.Name,
      trackingLink :item.ShipmentTrackingLink,
      products: productsString
      
    }
  }) : [];




const handleChangeDate = event => {
  setSelectedOption(event.target.value);

  let start, end;

  switch(event.target.value) {
    case "1": // Bugün
      start = new Date();
      end = new Date();
      break;

    case "2": // Bu hafta
      end = new Date();
      start = new Date();
      start.setDate(start.getDate() - start.getDay() + (start.getDay() === 0 ? -6 : 1));
      break;   

    case "3": // Bu ay
      end = new Date();
      start = new Date();
      start.setDate(1); // Ayın ilk günü
      break;
  
    default:
      // Özel tarih seçilirse başlangıç ve bitiş tarihleri korunur
      break;
  }

  setStartDate(start);
  setEndDate(end);
};

const [isOpenDate, setIsOpenDate] = useState(true);

const onChange = dates => {

  const [start, end] = dates;

  if (selectedOption === "4") {
    setStartDate(start);
    setEndDate(end);
  }
  if (start && end) {
    setIsOpenDate(false);  // tarihler seçildiyse datepicker'ı kapat
  }
};


const [selectedOption, setSelectedOption] = useState(null);


useEffect(() => {
  if (selectedOption === "4") {
    setIsOpenDate(true);
  } else {
    setIsOpenDate(false);
  }
}, [selectedOption]);



const handleSubmit = () => {
  const param = {
    // Add other parameters you need
    cargo :cargoModal,
    status: selectStatusModal,
    begin_date: startDateModal,
    end_date: endDateModal,
    // vendor_id: vendorExportId,
    store : storeModal,
    fulfilment : fulfilmentModal,
    invoices : invoicesExport,
    inventory_region : regionExport, 
    vendor_id : vendorIdList,
    city : citySelect,
    waybill:selectWaybill,
    deadlinedate: deadlinedateSelect,
    zpl: selectZpl,
    paid: paid,
    search : searchTerms,
    sku1 : sku1,
    sku1: sku2,
    product_count : productCount
  };
 
 
  getDataExport(param);
  setLoading(true)
  setTotalIdStatus(false)

 
};


// selectedCount, 
//     setSelectedCount


let selectedList = [];

const handleSelectedOrderIdsChange = (selectedOrderIds) => {
  console.log("Selected order IDs from OrdersWork: ", selectedOrderIds);
  console.log("selectedOrderIds: ", selectedOrderIds);

  selectedList = selectedOrderIds

};


const handleOrderIdAll = () => {
   setTotalIdStatus(true)
  //  handleSelectedOrderIdsChange(myData?.total_ids)

}

console.log("totalId", myData?.total_ids)



const handleSubmitWork = () => {
  if (totalIdStatus) {
    if (!myData?.total_ids || myData.total_ids.length === 0) {
      toast.warn("Toplu işlem için ID bulunamadı!");
      return;
    }

    switch (selectedAction) {
      case "waybill1":
        postDataWaybill_1(myData.total_ids);
        setLoading_1(true)
        break;
      case "waybill2":
        postDataWaybill_2(myData.total_ids);
        setLoading_2(true)
        break;
      case "waybill3":
        postDataWaybill_3(myData.total_ids);
        setLoading_3(true)
        break;
      default:
        toast.warn("Lütfen bir İrsaliye tipi seçin!");
    }
  } else {
    if (selectedList.length === 0) {
      toast.warn("Lütfen bir veya daha fazla öğe seçin!");
      return;
    }

    switch (selectedAction) {
      case "waybill1":
        postDataWaybill_1(selectedList);
        setLoading_1(true)
        break;
      case "waybill2":
        postDataWaybill_2(selectedList);
        setLoading_2(true)
        break;
      case "waybill3":
        postDataWaybill_3(selectedList);
        setLoading_3(true)
        break;
      default:
        toast.warn("Lütfen bir İrsaliye tipi seçin!");
    }
  }

  setMyData([]);

};


// async select

const [selectedValue, setSelectedValue] = useState("");
const [barcode, setBarcode] = useState("")


const handleInputChange = value => {
  setBarcode(value);

};

const handleChange = value => {
  if (value) {
    setSelectedValue(value);
    setSku1(value.SKU); 
    console.log("handleChange", value);
  } else {
    setSelectedValue(""); 
    setSku1(""); 
  }
};

const [selectedValue2, setSelectedValue2] = useState("");
const [barcode2, setBarcode2] = useState("")


const handleInputChange2 = value => {
  setBarcode2(value);

};

const handleChange2 = value => {
  if (value) {
    setSelectedValue2(value);
    setSku2(value.SKU); 
    console.log("handleChange", value);
  } else {
    setSelectedValue2(""); 
    setSku2(""); 
  }
}

const CustomClearText = props => (
  <components.ClearIndicator {...props}>
   <MdClear />
  </components.ClearIndicator>
);

const CustomClearText2 = props => (
  <components.ClearIndicator {...props}>
   <MdClear />
  </components.ClearIndicator>
)



const [selectedVendors, setSelectedVendors] = useState([]);

const handleChangeVendor = (selectedOptions) => {
  setSelectedVendors(selectedOptions); 

  const ids = selectedOptions?.map(option => option.value);
  setVendorIds(ids); 
};


  const vendorOptions = myDataVendor?.map((item) => ({
    value: item.Id,
    label: item.vendor_name,
  }));



  const tracking_filled = [
    {value:"true", label:"Var"},
    {value:"false", label:"Yok"},
  ]

  

  const handleChangeTrackingNumberEmpty = (e)=> {
    setTranckingNumber(e.target.value); 
  }



  return (
    <AdvanceTableWrapperOrderWaybill
      columns={columns}
      data={newList || []} 
      onSelectedOrderIdsChange={handleSelectedOrderIdsChange}
      selection
      sortable
      pagination
      perPage={50}
      // selectedCount
  
    >
      <Card className="mb-3">
<div style={{display:"flex", justifyContent:"space-between", marginTop:'15px', marginLeft:'15px', marginBottom:'30px'}}>
      {hasPermission('Dashboard Çoklu Satıcı Görme') && (
                      <div style={{ marginRight: "10px" }}>
                        {myDataVendor?.length > 0 && (
                          <Select
                            options={vendorOptions} 
                            isMulti 
                            onChange={handleChangeVendor} 
                            value={selectedVendors} 
                            placeholder="Satıcı Seçin"
                            
                          />
                        )}
                      </div>
                    )}
      </div>

<div style={{display:'flex', gap: '20px', justifyContent:'center', marginTop:'30px', width: '90%', margin: 'auto' }} >
  <Accordion defaultActiveKey="0" style={{marginBottom:'10px', flex: 1}}>
    <Accordion.Item eventKey="1" style={{ border: 'none', borderBottom: '1px solid #ccc', borderRadius:'0', marginRight:'10px', width: '100%' }}>
    <Accordion.Header> 
      <span style={startDateModal ? { color: '#2E58FF', fontWeight: 'bold' } : {}}>
      <CiCalendarDate style={{marginRight:'10px', fontSize:'20px'}}/>
      Tarih {startDateModal && <MdCheck style={{ marginLeft: '5px', fontWeight: 'bold', fontSize:'20px' }} />}
          </span>
      </Accordion.Header>
      <Accordion.Body>
         <div style={{marginRight:"8px", fontSize:"14px"}}>
               <DatePicker
               showIcon
               selected={startDateModal}
               onChange={onChangeModal}
               startDate={startDateModal}
               formatWeekDay={day => day.slice(0, 3)}
               endDate={endDateModal}
               selectsRange
               dateFormat="MMM dd"
               className='form-control'
               placeholderText='tarih giriniz'
               />
         </div>
         </Accordion.Body>
  </Accordion.Item>
</Accordion>

<Accordion defaultActiveKey="0" style={{marginBottom:'10px', flex: 1}}>
    <Accordion.Item eventKey="1" style={{ border: 'none', borderBottom: '1px solid #ccc', borderRadius:'0', marginRight:'10px', width: '100%' }}>
    <Accordion.Header>
        <span style={storeModal ? { color: '#2E58FF', fontWeight: 'bold' } : {}}>
          <CiShop style={{marginRight:'10px', fontSize:'20px'}}/>
          Mağaza Bilgileri {storeModal && <MdCheck style={{ marginLeft: '5px', fontWeight: 'bold', fontSize:'20px' }} />}
          </span>
        </Accordion.Header>
      <Accordion.Body>
         <div style={{marginRight:'8px'}}>
                   { vendorStore?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px", width:'90%'}} onChange={handleChangeStoreModal}>
                    <option value="" >Mağaza</option>
                       { vendorStore?.map((item, i)=>{
                             return <option key={i} value={item.Store__Name}>{item.Store__Name}</option>
                       })}       
               </Form.Select>}
           </div>
           </Accordion.Body>
  </Accordion.Item>
</Accordion>
</div>

<div style={{display:'flex', gap: '20px', justifyContent:'center', marginTop:'30px', width: '90%', margin: 'auto' }} >
  <Accordion defaultActiveKey="0" style={{marginBottom:'10px', flex: 1}}>
    <Accordion.Item eventKey="1" style={{ border: 'none', borderBottom: '1px solid #ccc', borderRadius:'0', marginRight:'10px', width: '100%' }}>
      <Accordion.Header>
        <span style={selectStatusModal ? { color: '#2E58FF', fontWeight: 'bold' } : {}}>
        <CiAlignBottom style={{marginRight:'10px', fontSize:'20px'}}/>
          Sipariş Statusu {selectStatusModal && <MdCheck style={{ marginLeft: '5px', fontWeight: 'bold', fontSize:'20px' }} />}
          </span>
        </Accordion.Header>
      <Accordion.Body>
        <div style={{marginRight:"8px"}}>
          <Form.Select  className='form-control' style={{fontSize:"15px", width:'90%'}} onChange={handleChangeModal}>
            <option value=""  disabled selected>Durumu</option>
            {orderStatus?.map((item, i)=>{
              return <option  key={i} value={item.value}>{item.label}</option>
            })}
          </Form.Select> 
        </div>
      </Accordion.Body>
    </Accordion.Item>
  </Accordion>

  <Accordion defaultActiveKey="0" style={{marginBottom:'10px', flex: 1}}>
    <Accordion.Item eventKey="1" style={{ border: 'none', borderBottom: '1px solid #ccc', borderRadius:'0', marginRight:'10px', width: '100%' }}>
      <Accordion.Header>
        <span style={selectZpl ? { color: '#2E58FF', fontWeight: 'bold' } : {}}>
        <CiBarcode style={{marginRight:'10px', fontSize:'20px'}}/>
        Zpl Barkod {selectZpl && <MdCheck style={{ marginLeft: '5px', fontWeight: 'bold', fontSize:'20px' }} />}
          </span>
        </Accordion.Header>
      <Accordion.Body>
        <div style={{marginRight:"8px"}}>
          <Form.Select  className='form-control' style={{fontSize:"15px", width:'90%'}} onChange={handleChangeZpl}>
            <option value="" >Zpl</option>
            {zpl?.map((item, i)=>{
              return <option  key={i} value={item.value}>{item.label}</option>
            })}
          </Form.Select> 
        </div>
      </Accordion.Body>
    </Accordion.Item>
  </Accordion>
</div>


<div style={{display:'flex', gap: '20px', justifyContent:'center', marginTop:'30px', width: '90%', margin: 'auto' }} >
  <Accordion defaultActiveKey="0" style={{marginBottom:'10px', flex: 1}}>
    <Accordion.Item eventKey="1" style={{ border: 'none', borderBottom: '1px solid #ccc', borderRadius:'0', marginRight:'10px', width: '100%' }}>
    <Accordion.Header>
      <span style={cargoModal ? { color: '#2E58FF', fontWeight: 'bold' } : {}}>
      < CiBoxes style={{marginRight:'10px', fontSize:'20px'}}/>
      Kargo {cargoModal && <MdCheck style={{ marginLeft: '5px', fontWeight: 'bold', fontSize:'20px' }} />}
          </span>
      </Accordion.Header>
      <Accordion.Body>
         <div style={{marginRight:"8px"}}>
                 { shipmentCompany?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px", width:'90%'}} onChange={handleChangeCargoModal}>
                 <option value="" >Kargo</option>
                     { shipmentCompany?.map((item, i)=>{
                           return <option key={i} value={item.ShipmentCompanyText}>{item.ShipmentCompanyText}</option>
                     })}       
             </Form.Select>}
         </div>
         <div style={{marginRight:"8px", marginTop:"10px"}}>
                 { tracking_filled?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px", width:'90%'}} onChange={handleChangeTrackingNumberEmpty}>
                 <option value="" >Takip Numarası</option>
                     { tracking_filled?.map((item, i)=>{
                           return <option key={i} value={item.value}>{item.label}</option>
                     })}       
             </Form.Select>}
         </div>
         </Accordion.Body>
  </Accordion.Item>
</Accordion>

<Accordion defaultActiveKey="0" style={{marginBottom:'10px', flex: 1}}>
    <Accordion.Item eventKey="1" style={{ border: 'none', borderBottom: '1px solid #ccc', borderRadius:'0', marginRight:'10px', width: '100%' }}>
    <Accordion.Header>
              <span style={(invoicesExport || invoiceCountry) ? { color: '#2E58FF', fontWeight: 'bold' } : {}}>
            <CiReceipt style={{ marginRight: '10px', fontSize: '20px' }} />
            Fatura 
            {(invoicesExport || invoiceCountry) && (
              <MdCheck style={{ marginLeft: '5px', fontWeight: 'bold', fontSize: '20px' }} />
            )}
          </span>
      </Accordion.Header>
      <Accordion.Body>

                <div className="mb-2">
                   <Form.Select  className='form-control' style={{fontSize:"15px", width:'90%'}} onChange={handleChangeExportinvoices}>
                           <option value="">Fatura</option>
                               {invoicesStatus?.map((item, i)=>{
                                     return <option  key={i} value={item.value}>{item.label}</option>
                               })}
                       </Form.Select> 
                 </div>

                 <div className="mb-2">
                   <Form.Select  className='form-control' style={{fontSize:"15px", width:'90%'}} onChange={handleChangeCountry}>
                           <option value="">Fatura Adresi Ülke</option>
                               {InvoiceCountry?.map((item, i)=>{
                                     return <option  key={i} value={item.value}>{item.label}</option>
                               })}
                       </Form.Select> 
                 </div>

              </Accordion.Body>
  </Accordion.Item>
</Accordion>
</div>

<div style={{display:'flex', gap: '20px', justifyContent:'center', marginTop:'30px', width: '90%', margin: 'auto' }} >
  <Accordion defaultActiveKey="0" style={{marginBottom:'10px', flex: 1}}>
    <Accordion.Item eventKey="1" style={{ border: 'none', borderBottom: '1px solid #ccc', borderRadius:'0', marginRight:'10px', width: '100%' }}>
    <Accordion.Header>
    <span style={regionExport ? { color: '#2E58FF', fontWeight: 'bold' } : {}}>
    < CiBank style={{marginRight:'10px', fontSize:'20px'}}/>
            Depo {regionExport && <MdCheck style={{ marginLeft: '5px', fontWeight: 'bold', fontSize:'20px' }} />}
          </span>
    </Accordion.Header>
      <Accordion.Body>
        <div style={{marginLeft:"8px"}}>
            { myDataRegion?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px", width:'90%'}} onChange={handleChangeRegionModal}>
            <option value="" >Depo</option>
            {myDataRegion?.map((item, i)=>{
            return <option key={i} value={item.Region__Name}>{item.Region__Name}</option>
            })}       
            </Form.Select>}
        </div>
         </Accordion.Body>
  </Accordion.Item>
</Accordion>

<Accordion defaultActiveKey="0" style={{marginBottom:'10px', flex: 1}}>
    <Accordion.Item eventKey="1" style={{ border: 'none', borderBottom: '1px solid #ccc', borderRadius:'0', marginRight:'10px', width: '100%' }}>
    <Accordion.Header>
      <span style={citySelect ? { color: '#2E58FF', fontWeight: 'bold' } : {}}>
    < CiMap style={{marginRight:'10px', fontSize:'20px'}}/>
    Şehir {citySelect && <MdCheck style={{ marginLeft: '5px', fontWeight: 'bold', fontSize:'20px' }} />}
          </span>
      
      </Accordion.Header>
      <Accordion.Body>
                 <div style={{marginLeft:"8px"}}>
                 { city?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px", width:'90%'}} onChange={handleChangeCity}>
                 <option value="">Şehir</option>
                     { city?.map((item, i)=>{
                           return <option key={i} value={item.value}>{item.label}</option>
                     })}       
             </Form.Select>}
         </div>
         </Accordion.Body>
  </Accordion.Item>
</Accordion>
</div>

<div style={{display:'flex', gap: '20px', justifyContent:'center', marginTop:'30px', width: '90%', margin: 'auto' }} >
  <Accordion defaultActiveKey="0" style={{marginBottom:'10px', flex: 1}}>
    <Accordion.Item eventKey="1" style={{ border: 'none', borderBottom: '1px solid #ccc', borderRadius:'0', marginRight:'10px', width: '100%' }}>
    <Accordion.Header>
      <span style={productCount ? { color: '#2E58FF', fontWeight: 'bold' } : {}}>
      < CiViewList style={{marginRight:'10px', fontSize:'20px'}}/>
      Ürün Filitresi {productCount && <MdCheck style={{ marginLeft: '5px', fontWeight: 'bold', fontSize:'20px' }} />}
          </span>
      
      </Accordion.Header>
      <Accordion.Body>

        <div style={{marginRight:'8px', marginBottom:'10px'}}>
                   { ProductCount?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px", width:'90%'}} onChange={handleChangeProductCount}>
                    <option value="" >Adetli mi?</option>
                       { ProductCount?.map((item, i)=>{
                             return <option key={i} value={item.value}>{item.label}</option>
                       })}       
               </Form.Select>}
        </div>

        <div style={{marginRight:'8px', marginBottom:'10px', width:'90%'}}>
            <Form.Group controlId="tek çeşit ürünler" className="mb-3"  >
              <Form.Label>tek çeşit ürünler</Form.Label>
          
          <AsyncSelect 
                loadOptions={getData}
                placeholder="isim, barkod, sku numarası" 
                className="mb-3" 
                onInputChange={handleInputChange}
                onChange={handleChange}
                value={selectedValue}
                getOptionLabel={e => `${e.Title} - ${e.SKU}`}
                getOptionValue={e => e.Barcode}
                isClearable
                components={{ ClearIndicator: CustomClearText }}
                styles={{control: (baseStyles) =>({
                  ...baseStyles, 
                  backgroundColor:isDark ? "#0B1727" : "white"
                }),}}
            /> 
          </Form.Group>
      </div>

      <div style={{marginRight:'8px', marginBottom:'10px', width:'90%'}}>
              <Form.Group controlId="çift çeşit ürünler" className="mb-3"  >
                <Form.Label>çiftli ürünler</Form.Label>
          <AsyncSelect 
                loadOptions={getData}
                placeholder="isim, barkod, sku numarası" 
                className="mb-3" 
                onInputChange={handleInputChange2}
                onChange={handleChange2}
                value={selectedValue2}
                getOptionLabel={e => `${e.Title} - ${e.SKU}`}
                getOptionValue={e => e.Barcode}
                isClearable
                components={{ ClearIndicator: CustomClearText2 }}
                styles={{control: (baseStyles) =>({
                  ...baseStyles, 
                  backgroundColor:isDark ? "#0B1727" : "white"
                }),}}
            /> 
          </Form.Group>
        </div>

         </Accordion.Body>
  </Accordion.Item>
</Accordion>

<Accordion defaultActiveKey="0" style={{marginBottom:'10px', flex: 1}}>
    <Accordion.Item eventKey="1" style={{ border: 'none', borderBottom: '1px solid #ccc', borderRadius:'0', marginRight:'10px', width: '100%' }}>
    <Accordion.Header>
      <span style={deadlinedateSelect ? { color: '#2E58FF', fontWeight: 'bold' } : {}}>
      < CiTimer style={{marginRight:'10px', fontSize:'20px'}}/>
      Termin Tarihi {deadlinedateSelect && <MdCheck style={{ marginLeft: '5px', fontWeight: 'bold', fontSize:'20px' }} />}
          </span>
      </Accordion.Header>
      <Accordion.Body>
                 <div style={{marginLeft:"8px"}}>
                 { deadlinedate?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px", width:'90%'}} onChange={handleChangeDeadlinedate}>
                 <option value="">Termin Tarihi</option>
                     { deadlinedate?.map((item, i)=>{
                           return <option key={i} value={item.value}>{item.label}</option>
                     })}       
             </Form.Select>}
         </div>
         </Accordion.Body>
  </Accordion.Item>
</Accordion>
</div>

<div style={{display:'flex', gap: '20px', justifyContent:'center', marginTop:'30px', width: '90%', margin: 'auto' }} >
  <Accordion defaultActiveKey="0" style={{marginBottom:'10px', flex: 1}}>
    <Accordion.Item eventKey="1" style={{ border: 'none', borderBottom: '1px solid #ccc', borderRadius:'0', marginRight:'10px', width: '100%' }}>
    <Accordion.Header>
      <span style={searchTerms.length > 0 ? { color: '#2E58FF', fontWeight: 'bold' } : {}}>
      < CiSearch style={{marginRight:'10px', fontSize:'20px'}}/>
      Anahtar Kelime {searchTerms.length > 0 && <MdCheck style={{ marginLeft: '5px', fontWeight: 'bold', fontSize:'20px' }} />}
          </span>
      </Accordion.Header>
      <Accordion.Body>
      <div>
            <InputGroup className="mb-3">
                <Form.Control
                    placeholder="Arama terimi ekleyin..."
                    value={currentTerm}
                    onChange={handleChangeSearch}
                />
                    <Button variant="primary" onClick={handleAddTerm}>Ekle</Button>
            </InputGroup>

            <div className="mb-3">
                {searchTerms.map(term => (
                    <Badge 
                        key={term} 
                        variant="primary" 
                        className="mr-2"
                        onClick={() => handleRemoveTerm(term)}
                        style={{ cursor: 'pointer', marginRight:"3px" }}
                    >
                        {term} ×
                    </Badge>
                ))}
            </div>

        </div>
         </Accordion.Body>
  </Accordion.Item>
</Accordion>

<Accordion defaultActiveKey="0" style={{marginBottom:'10px', flex: 1}}>
    <Accordion.Item eventKey="1" style={{ border: 'none', borderBottom: '1px solid #ccc', borderRadius:'0', marginRight:'10px', width: '100%' }}>
    <Accordion.Header>
   
      
      <span style={paid ? { color: '#2E58FF', fontWeight: 'bold' } : {}}>
      < CiCreditCard2 style={{marginRight:'10px', fontSize:'20px'}}/>
      Ödeme Durumu {paid && <MdCheck style={{ marginLeft: '5px', fontWeight: 'bold', fontSize:'20px' }} />}
          </span>
      </Accordion.Header>
      <Accordion.Body>
                 <div style={{marginLeft:"8px"}}>
                 { orderPaid?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px", width:'90%'}} onChange={handleChangePaid}>
                 <option value="">Ödeme Durumu</option>
                     { orderPaid?.map((item, i)=>{
                           return <option key={i} value={item.value}>{item.label}</option>
                     })}       
             </Form.Select>}
         </div>
         </Accordion.Body>
  </Accordion.Item>
</Accordion>
</div>

<div style={{display: 'flex', gap: '20px', marginTop: '10px', alignItems: 'flex-start', width:'45%', marginLeft:'65px'}} >
  <Accordion defaultActiveKey="0" style={{marginBottom:'10px', flex: 1}}>
    <Accordion.Item eventKey="1" style={{ border: 'none', borderBottom: '1px solid #ccc', borderRadius:'0', marginRight:'10px', width: '100%' }}>
    <Accordion.Header>
      <span style={selectWaybill ? { color: '#2E58FF', fontWeight: 'bold' } : {}}>
      < CiViewList style={{marginRight:'10px', fontSize:'20px'}}/>
      İrsaliye {selectWaybill && <MdCheck style={{ marginLeft: '5px', fontWeight: 'bold', fontSize:'20px' }} />}
          </span>
      </Accordion.Header>
      <Accordion.Body>
                 <div style={{marginLeft:"8px"}}>
                 { waybill?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px", width:'90%'}} onChange={handleChangeWaybill}>
                 <option value="" >İrsaliye Çıktı mı?</option>
                     { waybill?.map((item, i)=>{
                           return <option key={i} value={item.value}>{item.label}</option>
                     })}       
             </Form.Select>}
         </div>
         </Accordion.Body>
  </Accordion.Item>
</Accordion>

</div>



      <div style={{display:"flex", justifyContent:"end", marginTop:'35px', marginRight:'60px', marginBottom:'30px', flexGrow: 1}}>

     
        <div>
        <Button
          style={{width:'200px', margin: '0 auto', display: 'block', marginTop:'10px',backgroundColor:"#3BB685"}}
          onClick={handleSubmit}
          disabled={loading}
          >
          {loading ? <img src={gifOrder} alt="loading..." className="button-img" /> : "Siparişleri Önizle"}
        </Button>


        
        
    { myData?.count > 0 ? 
        <div style={{marginTop:'10px', textAlign:'end', display:'flex', justifyContent:'flex-end'}}>
           
            <p style={{marginRight:'10px'}}>  { totalIdStatus ? myData?.total_ids?.length : <OrdersTableHeader table /> || 0} </p> 
            <p style={{marginRight:'10px'}}>/</p> 
            <p >
              {totalIdStatus ? myData?.count :  myData?.results.length  || 0} seçildi.</p>
          </div> : ""
        }
         { myData?.count > 50 && (
          <p style={{ cursor: 'pointer', color:'blue', fontSize:'13px', textAlign:'end', marginTop:'-20px' }} 
            onClick={handleOrderIdAll}
            >
            {myData?.count} adet sipariş var, tümünü listele!
          </p>
        )}
         
        </div>    
      </div>
        
        <Card.Header>

      <div style={{display:"flex", justifyContent:"space-between"}}>
            <div>
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Siparişler</h5>
            </div>

            <div style={{display:"flex", width:'100%', justifyContent: 'flex-end', marginRight:'35px'}}>

            { ActionTypes?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px", width:'25%'}} onChange={handleChangeWaybillAction}>
                 <option value="" >İrsaliye Aksiyonu</option>
                     { ActionTypes?.map((item, i)=>{
                           return <option key={i} value={item.value}>{item.label}</option>
                     })}       
              </Form.Select>}

             <Button
             onClick={handleSubmitWork} 
             style={{marginLeft:'5px'}}
             disabled={loading_1 || loading_2 || loading_3 }
             > 
               {loading_1 || loading_2 || loading_3 ? <img src={gifOrder} alt="loading..." className="button-img" /> : "Git"}
             </Button>
            </div>
      </div>
</Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />


        <div style={{
            position: 'fixed',
            top: '50%',
            left: '60%',
            transform: 'translate(-50%, -50%)',
          }}>
            {showOrderGif && <img src={gifOrder} style={{width:'50%'}} alt="loading gif" />}
          </div>
        
        </Card.Body>
        <Card.Footer>
          {/* <AdvanceTablePagination table /> */}
          <PaginationOrder pages={totalPages} setCurrentPage={setCurrentPage} activePage={activePage} setActivePage={setActivePage}/>
        </Card.Footer>
       
      </Card>
      {/* <span style={{marginRight:"5px",marginLeft:"5px"}}>Sipariş sayısı</span>
      <Button variant={followersPerPage === 10 ? 'primary' : ''} onClick={() => setFollowersPerPage(10)}>10</Button>
      <Button variant={followersPerPage === 25 ? 'primary' : ''} onClick={() => setFollowersPerPage(25)}>25</Button>
      <Button variant={followersPerPage === 50 ? 'primary' : ''} onClick={() => setFollowersPerPage(50)}>50</Button>
      <span style={{marginLeft:"5px"}}> </span> */}


    </AdvanceTableWrapperOrderWaybill>
  );
};

export default OrdersPrint;





