import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext, useRef } from 'react'
import {BASE_URL} from './baseUrl'
import { toast } from 'react-toastify';




export default function useOrderWaybillList() {

  const [myDataS3, setMyDataS3] = useState()
  const {myKey, currentUser} = useContext(AuthContext)
  
  const [myDataResult, setMyDataResult] = useState()
  const [selectStatusModal, setSelectStatusModal] = useState()
  const [cargoModal, setCargoModal] = useState()
  const [storeModal, setStoreModal] = useState()
  const [fulfilmentModal, setFulfilmentModal] = useState()
  const [invoicesExport, setInvoicesExport] = useState()
  const [regionExport, setRegionExport] = useState()
  const [isPreparing, setIsPreparing] = useState(false);
  const [vendorExportId, setVendorExportId] = useState()
  const [myData, setMyData] = useState()
  const [sortAmount, setSortAmount] = useState(3)

const [currentTerm, setCurrentTerm] = useState('');
const [searchTerms, setSearchTerms] = useState([]);


  const todayDay = new Date();
  const oneDay = 1000 * 60 * 60 * 24;
  const timezoneOffset = todayDay .getTimezoneOffset(); 
  const [startDateModal, setStartDateModal] = useState(new Date(todayDay.getTime() - oneDay * 7));
  const [endDateModal, setEndDateModal] = useState(new Date(todayDay.getTime() + oneDay * 8));


  const [message, setMessage] = useState("");

  let formattedStartDate = "";
  let formattedEndDate = "";

  if(startDateModal){
    formattedStartDate = new Date(startDateModal.getTime()).toISOString().slice(0, 10);
  }
  if(endDateModal){
    formattedEndDate = new Date(endDateModal.getTime() + oneDay).toISOString().slice(0, 10);
  }

  const today = new Date();
  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0"); 
  const year = today.getFullYear();
  const formattedDate = `${day}.${month}.${year}`
  const [showOrderGif, setShowOrderGif] = useState(false);
  const [currentPage,setCurrentPage]=useState(1)
  const [followersPerPage, setFollowersPerPage] = useState(50);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1)
  const [vendorIdList, setVendorIdList] = useState()
  const isInitialRender = useRef(true);
  const [citySelect, setCitySelect] = useState();
  const [deadlinedateSelect, setDeadlinedateSelect] = useState();
  const [selectWaybill,setSelectWaybill] = useState()
  const [selectZpl,setSelectZpl] = useState()
  const [paid, setPaid] = useState()
  const [ascDate, setAscDate] = useState(3)
  const [ascDeadlineDate, setAscDeadlineDate] = useState(3)
  const [triggerExport, setTriggerExport] = useState(false);
  const [sku1, setSku1] = useState()
  const [sku2, setSku2] = useState()
  const [productCount, setProductCount] = useState()
  const [search, setSearch] = useState()
  const [totalIdStatus, setTotalIdStatus] = useState()
  const [ascWaybillDate, setAscWaybillDate] = useState(3)
  const [invoiceCountry, setInvoiceCountry] = useState() 
  const [vendorIds, setVendorIds] = useState([]);
  const [trackingNumber, setTranckingNumber] = useState()
  const [loading, setLoading] = useState(false)








 const getDataExport = async (showToast = true)=>{
      var myHeaders = new Headers();
      const offset = (currentPage - 1) * followersPerPage;
      const limit = followersPerPage;


      const params = {

        offset :offset,
        limit :limit,

        cargo :cargoModal,
        status: selectStatusModal,
        begin_date: formattedStartDate,
        end_date: formattedEndDate,
        // vendor_id: vendorIdList,
        vendor_ids: vendorIds,
        store : storeModal,
        fulfilment : fulfilmentModal,
        invoices : invoicesExport,
        inventory_region : regionExport,
        sort_amount : sortAmount,
        offset :offset,
        limit :limit,
        city: citySelect,
        waybill:selectWaybill,
        deadlinedate:deadlinedateSelect,
        zpl : selectZpl,
        search: searchTerms.join(','),
        paid: paid,
        asc_date : ascDate,
        asc_deadline_date : ascDeadlineDate,
        sku1: sku1,
        sku2: sku2,
        product_count: productCount,
        fulfilment : "1",
        total_ids : totalIdStatus,
        asc_waybill_date : ascWaybillDate,
        invoice_country : invoiceCountry,
        tracking_number_filled : trackingNumber

      };

//       const request_url = Object.entries(params)
//       .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
//       .reduce(
//         (url, [key, value]) => url + `&${key}=${value}`,
//         `${BASE_URL}api/v1/order/order_start_work/?`
        
// );
const request_url = `${BASE_URL}api/v1/order/order_start/?waybill=false&` + 
    Object.entries(params)
    .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
    .map(([key, value]) => `${key}=${value}`)
    .join('&');


    console.log("request_url", request_url)

var requestOptions = {
  method: 'GET',
  headers: {"Authorization": "Token " + myKey},
  redirect: 'follow'
};





fetch(request_url, requestOptions)
  .then(response => response.json())
  .then(result => {
    
    setMyData(result);
    setShowOrderGif(false);
    setTotalPages(Math.ceil(result.count / followersPerPage))
    // Toplam veri sayısını göster
    if (showToast) {
      if (result.count > 50) {
        toast.success(`${result.count} adet sipariş bulundu, 50 adet listeleniyor.`);
       
      } else if (result.count > 0) {
        toast.success(`${result.count} adet sipariş listelenmiştir.`);
       
      } else {
        toast.warning('İstenilen kriterlerde sipariş bulunmamaktadır.');
      }
      
      
    }
  })
  .catch(error => {
    console.log('error', error);
    if (showToast) {
      toast.error('Bir hata oluştu.');
    }
  }).finally(() => {
    setLoading(false); 
  });
 


}




useEffect(() => {
  if (isInitialRender.current) {
    isInitialRender.current = false; 
  } else {
    getDataExport(false); 
  }
}, [ascDate, ascDeadlineDate, triggerExport,currentPage, totalIdStatus, ascWaybillDate ]);


 return {myData,setMyData,setMyDataS3, selectStatusModal, setSelectStatusModal, startDateModal, setStartDateModal, endDateModal, setEndDateModal,  cargoModal, setCargoModal, getDataExport,storeModal, setStoreModal, fulfilmentModal, setFulfilmentModal, setShowOrderGif, showOrderGif, setInvoicesExport, invoicesExport, regionExport,setRegionExport, myDataResult, isPreparing, setIsPreparing, setMyDataResult, setVendorExportId, startDateModal, endDateModal, showOrderGif, currentPage,
  setCurrentPage,followersPerPage,setFollowersPerPage, totalPages, sortAmount, setSortAmount, setPage, setVendorIdList, vendorIdList, setCitySelect, citySelect, selectWaybill,setSelectWaybill, deadlinedateSelect, setDeadlinedateSelect, selectZpl,setSelectZpl, paid, setPaid, search, setSearch, currentTerm, setCurrentTerm, searchTerms, setSearchTerms, setAscDeadlineDate, setAscDate, ascDate, ascDeadlineDate, setTriggerExport, setSku1, setSku2, productCount, setProductCount, setTotalIdStatus,totalIdStatus, setAscWaybillDate, ascWaybillDate, invoiceCountry, setInvoiceCountry,setVendorIds, setTranckingNumber, setLoading, loading}
  
  
}
