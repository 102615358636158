import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'

import {BASE_URL} from './baseUrl'
import { DateContext } from 'context/DateContext';
import { PermsContext } from 'context/PermsContext';

export default function useEcomStatWait() {

 const [myDataWait, setMyDataWait] = useState()
 const {myKey, currentUser} = useContext(AuthContext)
 const { startDate, endDate } = useContext(DateContext);
 const [beginDateWait, setBeginDateWait] = useState()
 const [endDateWait, setEndDateWait] = useState()
 const {vendorIdContext, storeIdContext, regionContext} = useContext(PermsContext)


//bekleyen
 const getDataWaiting = ()=>{
  var myHeaders = new Headers();

  console.log("bekleyen", startDate, endDate)
  
      const params = {

        // begin_date: beginDateWait,
        // end_date: endDateWait,
        begin_date: startDate,
        end_date: endDate,
        // vendor_username: currentUser,
        vendor_id: vendorIdContext,
        store : storeIdContext,
        inventory_region : regionContext

      };

      const request_url = Object.entries(params)
          .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
          .reduce(
            (url, [key, value]) => url + `&${key}=${value}`,
            `${BASE_URL}api/v1/order/waiting_orders_today_count/?`
  );


  var requestOptions = {
    method: 'GET',
    headers: {"Authorization": "Token "+myKey},
    redirect: 'follow'
  };
  fetch(request_url, requestOptions)
    .then(response => response.json())
    .then(result =>{
      setMyDataWait(result) 

})
    .catch(error => console.log('error', error));        
}





 useEffect(() => {
  
  if (startDate && endDate) {
    getDataWaiting();
  }
  
  }, [beginDateWait,endDateWait, startDate, endDate, vendorIdContext, storeIdContext, regionContext]);

 return { myDataWait,setEndDateWait, setBeginDateWait}
}
