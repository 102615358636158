import {createContext,  useContext,  useEffect,  useMemo,  useState} from 'react'
import {BASE_URL} from '../hooks/baseUrl'
import { AuthContext } from './AuthContext';
import useVendorList from 'hooks/useVendorList';
import useInventoryRegion from 'hooks/useInventoryRegion';



export const PermsContext = createContext();



const PermsContextProvider = (props) =>{

const {myKey} = useContext(AuthContext)
const [perms, setPerms] = useState() 
const {myDataVendor} =useVendorList()
const {regionData} = useInventoryRegion()


const [vendorIdContext, setVendorIdContext] = useState() 
const [storeIdContext, setStoreIdContext] = useState()  
const [regionContext, setRegionContext] = useState() 
const [inventoryContext, setInventoryContext] = useState()


useEffect(() => {
  if (myDataVendor?.length > 0) {
    setVendorIdContext(myDataVendor[0]?.Id);
  }
}, [myDataVendor, perms]);





  useEffect(() => {
    if (regionData?.length > 0) {
      setInventoryContext(regionData[0].Id);
    }
  }, [regionData]);




const getPermissions = () => {
   
    var myHeaders = new Headers();

        let request_url;
        request_url = BASE_URL+"api/v1/users/user/list_permissions/"
        

        myHeaders.append("Authorization", `Token ${myKey}`);

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };  
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result => {
            if (JSON.stringify(result) !== JSON.stringify(perms)) {
              setPerms(result)
            }

    })
          .catch(error => console.log('error', error));

}


useEffect(() => {
 
    getPermissions()
   }, [myKey, vendorIdContext])


let value = {
    perms, 
    vendorIdContext,
    setVendorIdContext,
    storeIdContext, 
    setStoreIdContext,
    regionContext, 
    setRegionContext,
    inventoryContext,
    setInventoryContext
    
}

// let value = useMemo(() => ({
//   perms, 
//   vendorIdContext,
//   setVendorIdContext
// }), [perms, vendorIdContext, setVendorIdContext]);


return (
  <PermsContext.Provider value={value}>
        {props.children}
  </PermsContext.Provider>

)

}

export default PermsContextProvider