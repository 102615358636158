import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'

import {BASE_URL} from './baseUrl'
import { DateContext } from 'context/DateContext';
import { PermsContext } from 'context/PermsContext';

export default function useEcomStatSalesTotal() {

 
 
const [myDataSales, setMyDataSales] = useState()
const [beginDateSales, setBeginDateSales] = useState()
const [endDateSales, setEndDateSales] = useState()
const {vendorIdContext, storeIdContext, regionContext} = useContext(PermsContext)

const {myKey, currentUser} = useContext(AuthContext)
const { startDate, endDate } = useContext(DateContext);
 


//bekleyen
 const getDataSales= ()=>{
  var myHeaders = new Headers();
  

      const params = {

        begin_date: startDate,
        end_date: endDate,
        // vendor_username: currentUser,
        vendor_id : vendorIdContext,
        store : storeIdContext,
        inventory_region : regionContext

      };

      const request_url = Object.entries(params)
          .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
          .reduce(
            (url, [key, value]) => url + `&${key}=${value}`,
            `${BASE_URL}api/v1/order/sales_total/?`
  );



  var requestOptions = {
    method: 'GET',
    headers: {"Authorization": "Token "+myKey},
    redirect: 'follow'
  };
  fetch(request_url, requestOptions)
    .then(response => response.json())
    .then(result =>{
      setMyDataSales(result) 
})
    .catch(error => console.log('error', error));        
}





 useEffect(() => {
  
  if (startDate && endDate) {
    getDataSales();
  }
  
  }, [beginDateSales,endDateSales, startDate, endDate, vendorIdContext, storeIdContext, regionContext]);

 return { myDataSales, setBeginDateSales, setEndDateSales}
}
