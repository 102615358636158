import React, { useContext, useEffect, useState } from 'react';
import WeeklySales from './WeeklySales';
import { Row, Col,Form, Card } from 'react-bootstrap';
import {
  marketShare,
  totalOrder,
  totalSales,
  weeklySalesData,
  weather,
  products,
  storageStatus,
  files,
  users,
  topProducts,
  runningProjects,
 
} from 'data/dashboard/default';

import {totalSale, saleItems} from 'data/dashboard/ecom';
import { DateContext } from 'context/DateContext';
  


import TotalOrder from './TotalOrder';
import MarketShare from './MarketShare';
import TotalSalesCount from './TotalSalesCount';
import RunningProjects from './RunningProjects';
import StorageStatus from './StorageStatus';
import SpaceWarning from './SpaceWarning';
import BestSellingProducts from './BestSellingProducts';
import SharedFiles from './SharedFiles';
import ActiveUsers from './ActiveUsers';
import BandwidthSaved from './BandwidthSaved';
import TopProducts from './TopProducts';
import Weather from './Weather';
import TotalSales from '../e-commerce/totalsales/TotalSales';
import EcomStat from '../e-commerce/EcomStat';
import StatisticsCards from '../saas/stats-cards/StatisticsCards';
import PriceTotalForecast from './Forecasting/PriceTotalForecast';
import CargoShare from './CargoShare';
import StatisticsCardsRefend from '../saas/stats-cards-refund/StatisticsCardsRefend';
import StatisticsCardsRefendRate from '../saas/stats-cards-refund-rate/StatisticsCardsRefendRate';
import StatisticsCardsRefendCost from '../saas/stats-cards-refund-cost/StatisticsCardsRefendCost';
import BestProducts from './BestProducts';
import AppContext from 'context/Context';
import { PermsContext } from 'context/PermsContext';
import ErrorPage from './ErrorPage';
import useVendorList from 'hooks/useVendorList';





const Dashboard = () => {

const {
  config: { isDark, isRTL },
  setConfig
} = useContext(AppContext);


const {perms,  setVendorIdContext} = useContext(PermsContext)

  // function hasPermission(permission) {
  //   return perms?.includes(permission);
  // }

  function hasPermission(permission) {
    if (Array.isArray(perms)) {
      return perms.includes(permission);
    }
    return false;
  }
  

  const {myDataVendor} =useVendorList()

  const handleChangeVendor = (e)=> {
    setVendorIdContext(e.target.value); 
  }




useEffect(() => {
  if (myDataVendor?.length > 0) {
    setVendorIdContext(myDataVendor[0]?.Id);
  }
}, [myDataVendor, perms]);

if (!perms) {
  return null;
}


  return (

  
hasPermission('Dashboard Analiz Sayfası Full')  ?  (
<>
<div style={{display: "flex", justifyContent: "flex-end", marginRight: "8px"}}>
{ hasPermission('Dashboard Çoklu Satıcı Görme') ?  
    <Form.Select 
      className='form-control' 
      style={{fontSize:"15px", width:"145px", marginBottom:"10px"}} 
      onChange={handleChangeVendor}
    >
      <option value="" disabled>Satıcı</option> 
      {myDataVendor?.map((item, i) => {
        return <option key={i} value={item.Id}>{item.vendor_name}</option>
      })}       
    </Form.Select> : ""
  }
</div>
<Card className='mb-3'>
<Card.Header className="pb-0">
    <h6 className="mb-0 mt-2" style={{ color: isDark ? "#D8E2EF":"#05276D", fontSize:'15px' }}>
      Genel Özet
    </h6>
  </Card.Header>
  <Card.Body >
    <Row className="g-3">
      <Col md={4} xxl={4}>
        <WeeklySales data={weeklySalesData} />
      </Col>

      <Col md={4} xxl={4}>
      <TotalOrder data={totalOrder} />
      </Col>

      <Col md={4} xxl={4}>
        <StatisticsCards />
      </Col>
    </Row>
  </Card.Body>
</Card>

<Row className="g-3 mb-3">
<Col xxl={12}>
<EcomStat data={saleItems} />
</Col>
</Row>

<Row className="g-3 mb-3">
<Col xxl={12}>
{/* <BestProducts /> */}
</Col>
</Row>

{/* <Card className='mb-3'>
<Card.Header className="pb-0">
  <h6 className="mb-0 mt-2" style={{ color: isDark ? "#D8E2EF":"#05276D", fontSize:'15px'}}>
  Günlük Performans Grafikleri
  </h6>
</Card.Header>
<Card.Body >
  <Row className="g-3">
    <Col xxl={12} >
      <TotalSalesCount data={totalSales} />
    </Col>
    <Col xxl={12}>
        <TotalSales data={totalSale} />
    </Col>
</Row>
</Card.Body>
</Card> */}
</>
) : hasPermission('Dashboard Analiz Sayfası Partial') ? (
  <>
<div style={{display: "flex", justifyContent: "flex-end", marginRight: "8px"}}>
{ hasPermission('Dashboard Çoklu Satıcı Görme') ?  
    <Form.Select 
      className='form-control' 
      style={{fontSize:"15px", width:"145px", marginBottom:"10px"}} 
      onChange={handleChangeVendor}
    
    
    >
      <option value="" disabled>Satıcı</option> 
      {myDataVendor?.map((item, i) => {
        return <option key={i} value={item.Id}>{item.vendor_name}</option>
      })}       
    </Form.Select> : ""
  }
</div>
  <Row className="g-3 mb-3">
    <Col xxl={12}>
      <EcomStat data={saleItems} />
    </Col>
  </Row>
  <Row className="g-3 mb-3">
    <Col xxl={12}>
    <BestProducts />
    </Col>
  </Row>

  <Card className='mb-3'>
<Card.Header className="pb-0">
  <h6 className="mb-0 mt-2" style={{ color: isDark ? "#D8E2EF":"#05276D", fontSize:'15px'}}>
  Günlük Performans Grafikleri
  </h6>
</Card.Header>
<Card.Body >
  <Row className="g-3">
    <Col xxl={12} >
      <TotalSalesCount data={totalSales} />
    </Col>
    
</Row>
</Card.Body>
</Card>

  </>
): hasPermission('Dashboard Analiz Sayfası Low') ? (
  <>
<div style={{display: "flex", justifyContent: "flex-end", marginRight: "8px"}}>
{hasPermission('Dashboard Çoklu Satıcı Görme') ?  
    <Form.Select 
      className='form-control' 
      style={{fontSize:"15px", width:"145px", marginBottom:"10px"}} 
      onChange={handleChangeVendor}
    
    
    >
      <option value="" disabled>Satıcı</option> 
      {myDataVendor?.map((item, i) => {
        return <option key={i} value={item.Id}>{item.vendor_name}</option>
      })}       
    </Form.Select> : ""
  }
</div>
  <Row className="g-3 mb-3">
    <Col xxl={12}>
      <EcomStat data={saleItems} />
    </Col>
  </Row>
  <Row className="g-3 mb-3">
    <Col xxl={12}>
    <BestProducts />
    </Col>
  </Row>

  <Card className='mb-3'>
<Card.Header className="pb-0">
  <h6 className="mb-0 mt-2" style={{ color: isDark ? "#D8E2EF":"#05276D", fontSize:'15px'}}>
  Günlük Performans Grafikleri
  </h6>
</Card.Header>
<Card.Body >
  <Row className="g-3">
    <Col xxl={12} >
      <TotalSalesCount data={totalSales} />
    </Col>
    
</Row>
</Card.Body>
</Card>

  </>

  ): <ErrorPage/>
  )
};

export default Dashboard;
