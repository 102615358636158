import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'

import {BASE_URL} from './baseUrl'
import { PermsContext } from 'context/PermsContext';

export default function useEcomStatStock() {

 const [myDataStock, setMyDataStock] = useState()
 const [myDataStockDashboard, setMyDataStockDashboard] = useState()

 
  const [loading, setLoading] = useState(true);
 const {myKey, currentUser} = useContext(AuthContext)
 const [vendorIdStock, setVendorIdStok] = useState()
 const {vendorIdContext} = useContext(PermsContext)

 
//stok değeri
 const getDataStock = ()=>{
        var myHeaders = new Headers();

  
      const params = {
        vendor_id: vendorIdStock

      };

      const request_url = Object.entries(params)
          .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
          .reduce(
            (url, [key, value]) => url + `&${key}=${value}`,
            `${BASE_URL}api/v1/order/stock_value/?`
  );
 
        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setMyDataStock(result) 
    })
          .catch(error => console.log('error', error));        
 }


 //stok değeri
 const getDataStockDashboard = ()=>{
  var myHeaders = new Headers();


      const params = {
        vendor_id: vendorIdContext

      };

      const request_url = Object.entries(params)
          .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
          .reduce(
            (url, [key, value]) => url + `&${key}=${value}`,
            `${BASE_URL}api/v1/order/stock_value/?`
  );

  var requestOptions = {
    method: 'GET',
    headers: {"Authorization": "Token "+myKey},
    redirect: 'follow'
  };
  fetch(request_url, requestOptions)
    .then(response => response.json())
    .then(result =>{
      setMyDataStockDashboard(result) 
})
    .catch(error => console.log('error', error));        
}



 useEffect(() => {
  getDataStock();
  getDataStockDashboard()

  }, [vendorIdStock, vendorIdContext]);

 return {myDataStock, setVendorIdStok, myDataStockDashboard}
}
