import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'

import {BASE_URL} from './baseUrl'
import { DateContext } from 'context/DateContext';
import { PermsContext } from 'context/PermsContext';


export default function useTotalOrderCount() {

const {myKey, currentUser} = useContext(AuthContext)
 const [myData, setMyData] = useState()
//  const [beginDateCount, setBeginDateCount] = useState()
//  const [endDateCount, setEndDateCount] = useState()
 const { startDate, endDate } = useContext(DateContext);
 const {vendorIdContext, storeIdContext, regionContext} = useContext(PermsContext)

 
 const getData = ()=>{

        var myHeaders = new Headers();

          const params = {

            begin_date: startDate,
            end_date: endDate,
            // vendor_username: currentUser,
            vendor_id :  vendorIdContext,
            store:  storeIdContext,
            inventory_region : regionContext
          };
    
          const request_url = Object.entries(params)
              .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
              .reduce(
                (url, [key, value]) => url + `&${key}=${value}`,
                `${BASE_URL}api/v1/order/total_sales_count/?`
      );

       console.log("time", request_url)
        myHeaders.append("Authorization", `Token ${myKey}`);

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setMyData(result)  
    })
          .catch(error => console.log('error', error));
        
 }

 useEffect(() => {
  if (startDate && endDate) {
    getData();
  }
  }, [ startDate, endDate, vendorIdContext, storeIdContext, regionContext]);

 return {myData}
}
