import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'

import {BASE_URL} from './baseUrl'
import { DateContext } from 'context/DateContext';
import { PermsContext } from 'context/PermsContext';

export default function useEcomStatPrepare() {


 const [myDataPrepare, setMyDataPrepare] = useState()
 const [loading, setLoading] = useState(true);
 const {myKey, currentUser} = useContext(AuthContext)
 const { startDate, endDate } = useContext(DateContext);
 const {vendorIdContext, storeIdContext, regionContext} = useContext(PermsContext)
 


//hazırlanıyor
const getDataPrepare = ()=>{
  var myHeaders = new Headers();
 
  const params = {

    begin_date: startDate,
    end_date: endDate,
    // vendor_username: currentUser,
    vendor_id: vendorIdContext,
    store : storeIdContext,
    inventory_region : regionContext

  };

      const request_url = Object.entries(params)
          .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
          .reduce(
            (url, [key, value]) => url + `&${key}=${value}`,
            `${BASE_URL}api/v1/order/preparing_orders_today_count/?`
      );





  var requestOptions = {
    method: 'GET',
    headers: {"Authorization": "Token "+myKey},
    redirect: 'follow'
  };
  fetch(request_url, requestOptions)
    .then(response => response.json())
    .then(result =>{
      setMyDataPrepare(result)
      setLoading(false)

})
    .catch(error => console.log('error', error));        
}




 useEffect(() => {
  
 
  
  if (startDate && endDate) {
    getDataPrepare();
  }
  }, [storeIdContext, vendorIdContext, regionContext, startDate, endDate]);

 return {myDataPrepare,loading, setLoading}
}
